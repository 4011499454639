const state = {
  faqs: [
    {
      key: 'executorDuties',
      question: 'What are the duties of an Executor?',
      answer: `
<p>An Executor is responsible for identifying, collecting and protecting estate assets, paying estate liabilities and distributing the assets of the estate to the beneficiaries in accordance with your Will. They are also responsible for managing any tax obligations as well as any  legal and financial paperwork that may arise.</p>
<p>You can nominate up to 3 primary Executors. If you wish, you can also choose up to 3 backup options for each Executor you nominate.</p>`,
    },
    {
      key: 'executorOverseas',
      question: 'Can an Executor reside overseas?',
      answer: `
<p>Whilst you may appoint an Executor who resides outside of Australia, logistical challenges may flow from such an appointment. Therefore, it is generally recommended that you appoint an executor who lives in Australia.</p>
`,
    },
    {
      key: 'accountExpires',
      question: "What happens if I don't renew my account?",
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'updateRelationship',
      question: 'What if my relationship status changes in the future?',
      answer: `
<p>If your relationship status changes in the future we recommend that you come back and update your Will.</p>
`,
    },
    {
      key: 'timeToCreate',
      question: 'How long does it take to create my Will?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'neededDocuments',
      question: 'What documentation do I need?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'petGift',
      question: 'Can I leave a gift to a pet?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'petRecipient',
      question: 'Who can I leave a pet to?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'giftsWhat',
      question: 'What sorts of things can I leave as a gift?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'giftsWhatNot',
      question: "What can't I leave as a gift?",
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'giftsCharities',
      question: 'How do I leave something to charity?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'nonEstateAssets',
      question: 'Who can I leave a pet to?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'vaultAccess',
      question: 'Who will have access to my Willed Vault?',
      answer: `
<p>Only you will be able to access your Willed Digital Vault until you pass away.</p>
<p>After that your Executors will need to contact Willed to request access to the information you have stored in your Digital Vault.</p>
`,
    },
    {
      key: 'wishesTest',
      question: 'Lorem ipsum dolor emet?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'guardiansStepChildren',
      question: 'Should I include step children here?',
      answer: `
<p>You can only nominate guardians for children you have legal guardianship of. Do not include step children in this section unless you have legally adopted them.</p>
`,
    },
    {
      key: 'whatIsEstate',
      question: 'What is my estate?',
      answer: `
<p>Your estate is an inventory of everything you own at the time of your death. It may include real estate, cash in the bank, shares, motor vehicles, digital assets and personal possessions, such as furniture, paintings and jewellery.</p>
<p>Your residual estate is the remainder of your estate once all debts and expenses have been paid, and specific gifts have been distributed. Your residual estate will be distributed to the beneficiaries you nominate in this step.</p>
<p>If you leave your residual estate to more than one beneficiary you will need to allocate a percentage to each one. The percentages can be equal or unequal, but they must add up to 100%.</p>
<p>Assets held as joint proprietors or owned as part of a family trust or superannuation may not form part of your estate.</p>
`,
    },
    {
      key: 'leavingSpecificGift',
      question: 'How do I leave a specific gift instead of a percentage?',
      answer: `
<p>A gift is generally a specific asset, item or designated amount of money you choose to leave to a particular beneficiary. When giving to charity, this is also known as a Gift in Will or bequest.</p>

<p>If you’d like to, you can allocate specific gifts in the next step of your Will writing journey.</p>

<p>Specific gifts will be taken out of your estate before it’s divided amongst the beneficiaries you nominate in this step.</p>
`,
    },
    {
      key: 'whatIsBeneficiary',
      question: 'What is a beneficiary?',
      answer: `
<p>A beneficiary is an individual or entity that you list in your Will as being entitled to receive assets from your estate when you die.</p>
<p>Willed allows you to select individuals or Australian charities as your beneficiaries.</p>
`,
    },
    {
      key: 'whatIfBeneficiaryUnder18',
      question:
        'What if one or more of my beneficiaries are under 18 years old?',
      answer: `
<p>If a beneficiary is under 18 years old at the time of your death, their inheritance will be held for them in trust until they reach that age.</p>
<p>In the meantime, their guardian will be able to apply to the Executor of your estate to seek any funds required for their care. These funds will come from the beneficiary’s inheritance. For this reason, we recommend that you nominate different people in the roles of Executor and Guardian.</p>
`,
    },
    {
      key: 'vaultInfo',
      question: 'What is a Willed Digital Vault?',
      answer: `
<p>Your Willed Digital Vault is a secure place to collect and maintain important information relating to the management of your estate.</p>
<p>You can include personal messages for friends and family, list your assets and liabilities and keep track of contact information for important people.</p>
<p>After you die your Executor will be able to use the information left in your Willed Vault to manage your estate more easily. They will also be able to share messages with your loved ones.</p>
`,
    },
    {
      key: 'whatGuardian',
      question: 'What is a guardian?',
      answer: `
<p>Nominating guardians in your legal Will is one of the best places to specify who should look after your dependent children, if both parents pass away.</p>
<p>By documenting your guardianship wishes, you can avoid the court deciding who should care for your children.</p>
`,
    },
    {
      key: 'whoGuardian',
      question: 'Who should I appoint as a guardian?',
      answer: `
<p>Selecting the right guardian for your children is no easy task. There are many factors to consider, including the guardian’s age, location, lifestyle & values, finances, religion and whether they already have an existing family or not.</p>
<p><a href="https://www.willed.com.au/guides/guardianship/" target="_blank">Read more</a>.</p>
`,
    },
    {
      key: 'whoGuardian',
      question: 'Who should I appoint as a guardian in my Will?',
      answer: `
<p>Selecting the right guardian for your children is no easy task. There are many factors to consider, including the guardian’s age, location, lifestyle & values, finances, religion and whether they already have an existing family or not.</p>
<p><a href="https://www.willed.com.au/guides/guardianship/" target="_blank">Read more</a>.</p>
`,
    },
    {
      key: 'whatWishes',
      question: 'What are Funeral Wishes?',
      answer: `
<p>In the Funeral Wishes section of your Willed account you can save details about how you would like to be celebrated and what you would like to happen to your body after you die.</p>
<p>After you pass away your Executor(s) will be able to access your Funeral Wishes from your account or you can also download a copy to keep with your Will. Just don't attach it to your Will.</p>
`,
    },
    {
      key: 'whereStoreWishes',
      question: 'What do I do with my Funeral Wishes?',
      answer: `
<p>Your Funeral Wishes will be stored in your account and will be provided to your Executor(s) after you have passed away.</p>
<p>You can also download your Funeral Wishes and print them out to keep with your other documents or share with someone you trust, such as family, friends or your Executor(s).</p>
<p>Do not attach it to your Will though.</p>
`,
    },
    {
      key: 'loremIpsum',
      question: 'Lorem ipsum dolor emet?',
      answer: `
<p>Lorem ipsum.</p>
`,
    },
    {
      key: 'differenceWillPOA',
      question: 'What’s the difference between a Will and a POA?',
      answer: `
<p>A Will and a Power of Attorney are both important documents, but they serve different purposes.</p>
<p>A Will is used to distribute your property after you die, while a Power of Attorney is used to give someone else the authority to make decisions on your behalf if you become unable to do so yourself.</p>
<p>Both documents are essential when it comes to estate planning.</p>
`,
    },
    {
      key: 'placeKeepPOA',
      question: 'Where is the best place to keep Powers of Attorney?',
      answer: `
<p>As a legal document, it is important to keep your signed Powers of Attorney in a safe and secure place that is easily accessible for your nominated attorney.
</p>
<p>If you choose to store your original Powers of Attorney at home, a fireproof and waterproof safe or metal box is the best option. If you can’t store it at home, consider a safety deposit box. Alternatively, you might like to give it to your nominated attorney.</p>
`,
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
