import Vue from 'vue'

import { pricingRef } from '@/plugins/firebase'

import { db } from '../../../plugins/firebase'

const getters = {
  pricing: ({ pricing }) => {
    return pricing || {}
  },
  discount_code: ({ discountCode }) => {
    return discountCode || null
  },
}

const actions = {
  async load_pricing({ commit }) {
    const pricingDoc = await pricingRef.get()

    if (pricingDoc.exists) {
      commit('setPricing', pricingDoc.data())
    }
  },
  async get_prepaid_doc(_, code) {
    let doc = await db.collection('prepaid').doc(code).get()
    if (doc.exists) {
      return doc.data()
    }

    return null
  },
  async load_promo_codes({ commit, state }) {
    if (state.promoCodes) {
      return true
    }
    let doc = await db.collection('utilityDocs').doc('promoCodes').get()
    if (doc.exists) {
      let allCodes = doc.data()
      let activeCodes = {}
      Object.keys(allCodes).forEach((code) => {
        if (allCodes[code].active) {
          activeCodes[code] = allCodes[code]
        }
      })
      commit('SET_PROMO_CODES', activeCodes)
    }

    return true
  },
}

const mutations = {
  setPricing(state, data) {
    Vue.set(state, 'pricing', data)
  },
  SET_DISCOUNT_CODE(
    state,
    { type = 'promocode', price, code, pricePoa, skipCard = false }
  ) {
    Vue.set(state, 'discountCode', {
      type,
      will: Number(price),
      code,
      skipCard,
      ...((pricePoa || pricePoa === 0) && { poa: Number(pricePoa) }),
    })
  },
  UNSET_DISCOUNT_CODE(state) {
    Vue.set(state, 'discountCode', null)
  },
  SET_PROMO_CODES(state, codes) {
    Vue.set(state, 'promoCodes', codes)
  },
}

export default {
  getters,
  actions,
  mutations,
}
